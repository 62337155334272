<template>
    <BaseControl v-bind="$attrs" :errors="combinedErrors">
        <tiny-mce :config="config" v-model="local" />
    </BaseControl>
</template>
<script>
import BaseControl from "@Platon/components/form/controls/BaseControl.vue"

import ValidationMixin from "@Platon/mixins/ValidationMixin"
import InputControl2Mixin from "@Platon/mixins/InputControl2Mixin"

import TinyMCE from "./TinyMCE.vue"

export default {
    props: {
        value: {}
    },
    components: { BaseControl, TinyMCE },

    mixins: [InputControl2Mixin, ValidationMixin],

    computed: {
        config() {
            return { ...this.item, width: "100%" }
        },

        local: {
            get() {
                return this.value
            },

            set(val) {
                this.$emit("input", val)
            }
        }
    }
}
</script>

<style>
.tox.tox-silver-sink.tox-tinymce-aux {
    z-index: 2200;
}
.tox-promotion {
    display: none;
}
</style>
